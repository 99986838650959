import { waitForElement } from '@aph/utilities/wait-for-element/wait-for-element';
import {
  ONETRUST_DPD_CONTENT,
  ONETRUST_DPD_SELECTOR,
  ONETRUST_DPD_TITLE,
  ONETRUST_PC_DESCRIPTION_CONTENT,
  ONETRUST_PC_DESCRIPTION_SELECTOR,
  ONETRUST_POLICY_TEXT_CONTENT,
  ONETRUST_POLICY_TEXT_SELECTOR,
} from './cookie-consent.constants';

export const replaceCookieConsentStrings = () => {
  waitForElement(ONETRUST_POLICY_TEXT_SELECTOR).then((e) => {
    e.innerHTML = ONETRUST_POLICY_TEXT_CONTENT;
  });
  waitForElement(ONETRUST_PC_DESCRIPTION_SELECTOR).then((e) => {
    const descriptionElement = e.firstChild;
    if (descriptionElement?.textContent) {
      descriptionElement.replaceWith(document.createTextNode(ONETRUST_PC_DESCRIPTION_CONTENT));
    }
  });
  waitForElement(ONETRUST_DPD_SELECTOR).then((e) => {
    const titleElement = e.querySelector('.ot-dpd-title');
    if (titleElement?.textContent) {
      titleElement.textContent = ONETRUST_DPD_TITLE;
    }

    const descriptionParagraph = e.querySelector('.ot-dpd-desc');
    const firstDescriptionElement = descriptionParagraph?.firstChild;
    if (firstDescriptionElement?.textContent) {
      firstDescriptionElement.textContent = ONETRUST_DPD_CONTENT;
    }
  });
};

export const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk');
  if (otConsentSdk) {
    otConsentSdk.remove();
  }

  if (typeof window !== 'undefined' && window.OneTrust != null) {
    setTimeout(() => {
      /**
       * This is a temporary fix for the cookie consent banner.
       * By the end of October, the new version of the cookie consent script should solve this issue
       * and this fix can be removed after deploying the 202410.x.x version.
       */
      replaceCookieConsentStrings();
      window.OneTrust.Init();
      setTimeout(() => {
        window.OneTrust.LoadBanner();
      }, 1000);
    }, 1000);
  }
};

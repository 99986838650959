export const ONETRUST_POLICY_TEXT_SELECTOR = '#onetrust-policy-text';
export const ONETRUST_PC_DESCRIPTION_SELECTOR = '#ot-pc-desc';
export const ONETRUST_POLICY_TEXT_CONTENT =
  'Vi och våra partners lagrar eller får åtkomst till information på din enhet, genom användning av kakor, för att webbplatsen ska fungera samt för att förbättra din upplevelse, följa upp på hur webbplatsen används och visa annonser. Du kan acceptera eller hantera dina val genom att klicka nedan eller när som helst på sidan för dataskyddspolicy. Dina val kommer att signaleras till våra partners.';
export const ONETRUST_PC_DESCRIPTION_CONTENT =
  'Vi behandlar dina uppgifter för att leverera innehåll eller annonser och mäter leveransen av sådant innehåll eller annonser för att få insikter om vår webbplats. Vi delar denna information med våra partners beroende på samtycke. Du kan utöva din rättighet att samtycka, baserat på ett specifikt syfte, under eller på partnernivå i länken under varje syfte. Dessa val kommer att signaleras till våra leverantörer som deltar i ramverket för öppenhet och samtycke. Du kan inte neka kakor eller andra spårningstekniker som är strikt nödvändiga för att webbplatsen ska fungera samt för att säkerställa säkerhet och förhindra bedrägerier.';
export const ONETRUST_DPD_SELECTOR = '.ot-dpd-container';
export const ONETRUST_DPD_CONTENT =
  'Lagra och/eller få åtkomst till information på enheten, Använda begränsade data för att välja annonser, Skapa profiler för personaliserade annonser, Använda profiler för att välja personaliserade annonser, Mäta reklamprestanda, Förstå målgrupper genom statistik eller kombinationer av data från olika källor, Utveckla och förbättra tjänster.';
export const ONETRUST_DPD_TITLE = 'Vi och våra partners behandlar data för att:';

import React from 'react';
import { cva } from 'class-variance-authority';
import * as ICONS from '@aph/icons/new-ds';
import type { iconColor } from '@aph/ui/tailwind/classes/icon-color';
import { cn } from '../../tailwind/cn';
import type { CustomVariantProps } from '../../tailwind/variant-props';

export type IconName = keyof typeof ICONS;

const variations = cva('flex flex-shrink-0 items-center justify-center', {
  variants: {
    size: {
      extraLarge: 'h-[32px] w-[32px]',
      large: 'h-[24px] w-[24px]',
      medium: 'h-[20px] w-[20px]',
      small: 'h-[16px] w-[16px]',
    },
  },
  defaultVariants: {
    size: 'large',
  },
});

export interface IconProps
  extends React.SVGProps<SVGSVGElement>,
    CustomVariantProps<typeof variations> {
  name: IconName;
  fill?: `fill-${keyof typeof iconColor}`;
}

/**
 *
 * @example
 * ```tsx
 * <Icon name="name of icon" />
 * ```
 *
 */
export const Icon: React.FC<IconProps> = ({
  name,
  size,
  fill = 'fill-default',
  className,
  ...rest
}) => {
  if (!name) {
    return null; // or handle the case when name is null or undefined
  }
  const Comp = ICONS[name];
  return <Comp aria-hidden className={cn(variations({ size }), fill, className)} {...rest} />;
};
